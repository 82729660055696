@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600&family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600&family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Port+Lligat+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap");

@font-face {
  font-family: "banglaFont";
  src: url("/src/fonts/Li_Ador_Noirrit_Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.font-ador {
  font-family: "banglaFont";
}

.tiro-bangla-regular {
  font-family: "Tiro Bangla", serif;
  font-weight: 400;
  font-style: normal;
}

.hind-siliguri {
  font-family: "Hind Siliguri", sans-serif;
}
.blueGradiant {
  background: linear-gradient(
    162deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 115, 158, 1) 53%,
    rgba(1, 102, 154, 1) 70%,
    rgba(9, 9, 121, 1) 86%
  );
}
.bg-gradient-top {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.6%, #000 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 42%),
    linear-gradient(90.33deg, #092847 32.92%, #092847 99.73%);
}
.bg-gradient-bottom {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 31.6%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 42%, rgba(0, 0, 0, 0.9) 100%),
    linear-gradient(90.33deg, #092847 32.92%, #092847 99.73%);
}
.swiper-slide-active > .swiperactivecard {
  box-shadow: 0 0 15px 0 #77ddff;
  background-color: #fff;
}
.formBackground {
  /* background-image: url("../src/assets/NewDesign/Home/BannerBackground.png"); */
  background: radial-gradient(ellipse at bottom left, #136c77, transparent 35%),
    radial-gradient(circle at top right, #2a1b5a, transparent 50%),
    radial-gradient(circle at bottom right, #7b1158, transparent 35%),
    linear-gradient(130deg, #1b2e56, #070b26);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.formBackground .card {
  background-color: #f1f5f9;
}
.form-control {
  width: 100% !important;
  height: 38px !important;
}
.CreateJob
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 10px;
}
.CreateJob .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px !important;
}
.navBarAccordion .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
  min-width: 40px !important;
}
.navBarAccordion .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: transparent !important;
  box-shadow: none !important;
}
.navBarAccordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}
.css-137ul40-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 0 !important;
}
/* .css-17ety4d-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #092847 !important;
  color: #fff !important;
} */
.css-ascpo7-MuiStepLabel-root {
  display: flex;
  flex-direction: column-reverse !important;
}
.profileExp .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.profileExp .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border-radius: 8px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type {
  width: inherit !important;
}
@media (max-width: 500px) {
  .css-19g3cy2 {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  .CreateJob_quillbox__lT4W7 > div:nth-of-type(2) > div {
    height: 88px !important;
  }
}
.AllCourse
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 !important;
  padding-right: 32px !important;
}
@media (max-width: 500px) {
  .AllCourse
    .css-1iht6m0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    font-size: 12px !important;
  }
}
#AllCourse
  .css-1iht6m0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.CourseModuleAccordion
  > .css-t65zhe-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-bottom-left-radius: 24px !important;
  border-bottom-right-radius: 24px !important;
}
.CourseModuleAccordion
  > .css-t65zhe-MuiPaper-root-MuiAccordion-root:last-of-type {
  border-top-left-radius: 24px !important;
  border-top-right-radius: 24px !important;
}
.courseDetails {
  /* background-image: url("../src/assets/NewDesign/Home/BannerBackground.png"); */
  background: radial-gradient(ellipse at bottom left, #136c77, transparent 35%),
    radial-gradient(circle at top right, #2a1b5a, transparent 50%),
    radial-gradient(circle at bottom right, #7b1158, transparent 35%),
    linear-gradient(130deg, #1b2e56, #070b26);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper {
  position: static !important;
}
.swiper-button-next {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 110% !important;
  right: 46% !important;
  bottom: 0;
  z-index: 10;
}
.swiper-button-next::after {
  content: "";
  background-image: url(../src/assets/NewDesign/SingleCoursePage/ArrowRight.png);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: block;
}
.swiper-button-prev {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  top: 110%;
  left: 46% !important;
  bottom: 0;
  z-index: 70;
}
.swiper-button-prev::after {
  content: "";
  background-image: url(../src/assets/NewDesign/SingleCoursePage/ArrowLeft.png);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: block;
}
@media (max-width: 500px) {
  .swiper-button-next {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 110% !important;
    right: 35% !important;
    bottom: 0;
    z-index: 10;
  }
  .swiper-button-prev {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 110% !important;
    left: 35% !important;
    bottom: 0;
    z-index: 70;
  }
}
.swiper-button-next {
  right: 20px;
}
.swiper-button-prev {
  left: 20px;
}
.inputStyle {
  display: flex;
  width: 100%;
  padding: 14px 20px 14px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  border: 1px solid var(--Navy, #04000556);
  background: var(--White, #f5f3f3);
}
.inputStylePhone {
  display: flex;
  width: 100%;
  padding: 18px 24px 19px 0;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Navy, #17152f);
  background: var(--White, #fff);
}
.authSubmitButton {
  display: flex;
  width: 100%;
  height: 48px;
  color: #fff;
  padding: 14px 24px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--Magenta, #fc1c69);
}
.authGoogleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  gap: 5px;
  width: 100%;
  height: 40px;
  color: #17152f;
  padding: 14px 24px;
  cursor: pointer;
  gap: 10px;
  border-radius: 999px;
  border: 1px solid #374e5e;
}
.css-67l5gl {
  background-color: transparent !important;
}
.css-wf16b5 {
  background-color: #092847 !important;
}
.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}
.menu-items {
  position: relative;
  font-size: 16px;
}
.menu-items a {
  display: block;
  font-size: 16px;
  color: #000;
  text-decoration: none;
}
.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}
button span {
  margin-left: 3px;
}
.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}
.menu-items a:hover,
.menu-items button:not(:first-child):hover {
  background-color: #e0e0e0;
}
.menu-items .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}
.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 12rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}
.dropdown.show {
  display: block;
}
.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
.regForm .react-tel-input .form-control {
  border: none;
}
.inputStylePhone {
  padding: 8px 12px 8px 0;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #f5f5f5;
  border: 1px solid #cacaca;
}
.react-tel-input .flag-dropdown.open {
  z-index: 2;
  background: #fff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.bestSelling .swiper-pagination {
  margin-right: 20px;
  top: 85% !important;
}
.swiper-pagination-bullet {
  background-color: #fff !important;
}
.hearForm .swiper-pagination {
  margin-right: 20px;
  top: 40% !important;
}
.hearForm .swiper-pagination-bullet {
  background-color: #fff !important;
}
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
  animation: rotation 2s ease-in-out infinite alternate;
}
.loader::after {
  border-color: #ff3d00;
  animation-direction: alternate-reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.linkedin input:checked {
  background-color: #22c55e;
}
.linkedin input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem;
}
.portfolio input:checked {
  background-color: #22c55e;
}
.portfolio input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem;
}
#CvQuill .ql-toolbar.ql-snow {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-color: #000;
}
#CvQuill .ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-color: #000;
}
#CvQuill .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #000;
  border-radius: 6px;
}
.Ailoader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: #fff;
}
.Ailoader:before,
.Ailoader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.Ailoader:after {
  color: #ff3d00;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}
@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0 0 0 currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0 currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0 -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
.responsibilities ul,
.requirements ul,
.benefits ul {
  list-style-type: circle;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
}
.homeBanner .css-1pqm26d-MuiAvatar-img {
  object-fit: contain;
}
.homeBanner .css-1hy9t21 {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: contain !important;
  color: transparent;
  text-indent: 10000px;
}

/* demo video player css */
.demo-player-wrapper {
  position: relative;
  padding-top: 56.25%;
  border-radius: 6px;
}

.demo-react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 6px;
  overflow: hidden;
}

/* modifed course details page whats app box css */
.whatsapp-box {
  position: fixed;
  bottom: 60px;
  right: 50px;
  transition: all 0.3s ease-in-out;
  transform-origin: bottom right;
  z-index: 3999;
}

.whatsapp-box-hidden {
  transform: scale(0);
  opacity: 0;
}

.whatsapp-box-visible {
  transform: scale(1);
  opacity: 1;
}

/* join-facebook page animation button style */

.animated-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #00e59c, #24322d, #008198);
  background-size: 200% 100%;
  animation: gradientMove 5s ease infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-arrow-move {
  animation: arrowMove 1.5s ease-in-out infinite;
}

@keyframes arrowMove {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(4px);
  }
}

/* Join facebook button text animation */
@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@media (min-width: 768px) {
  .zoom-text {
    animation: zoom 3s infinite;
  }
}

.scroll-design {
  transition: width 0.3s ease;
  overflow-y: scroll;
  height: 100vh;
}

.scroll-design {
  transition: width 0.3s ease;
  overflow-y: scroll;
  height: 100vh;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.scroll-design::-webkit-scrollbar {
  width: 8px;
}

.scroll-design::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-design::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

.scroll-design::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* data summit button animation */
@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

.eidOffer {
  background-image: url("../src/assets/eid_offer_button.jpg");
  background-position: bottom left -5px;
  background-size: contain;
  inset: 0;
  opacity: 15%;
}

.eid-course {
  background-image: url("../src/assets/eid-offer-bgpage.jpg");
  background-position: center;
  background-size: 100% 100%;
}
